import { format, setDefaultOptions } from 'date-fns'
import { tz } from '@date-fns/tz'
import { de } from 'date-fns/locale'
setDefaultOptions({ locale: de })

export default function (type: string, date: string): string {
  if (type === 'utc') {
    return `${date}Z`
  } else if (type === 'time') {
    return format(date, 'HH:mm')
  } else if (type === 'fullDate') {
    return format(date, 'dd.MM.yyyy')
  } else if (type === 'fullDateShort') {
    return format(date, 'dd.MM.yy')
  } else if (type === 'fullDateWeekday') {
    return `${format(date, 'EEEE')}, ${format(date, 'dd.MM.yyyy')}`
  } else if (type === 'fullDateWeekdayMonth') {
    return `${format(date, 'EEEE')}, ${format(date, 'dd.MMMM yyyy')}`
  } else if (type === 'weekday') {
    return format(date, 'EEEE')
  } else if (type === 'archiveDate') {
    return format(date, 'yyyy-MM-dd')
  } else if (type === 'archiveTime') {
    return format(date, 'HH-mm-ss')
  } else if (type === 'graphqlFilter') {
    const options = {
      in: tz('utc')
    }
    const formattedDate = format(date, 'yyyy-MM-dd', options)
    const formattedTime = format(date, 'HH:mm:ss', options)
    return `${formattedDate}T${formattedTime}`
  }
}
